<template>
  <v-container fluid class="mt-4">
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <v-text-field
	  		      label="Fecha de inicio"
	  		      filled
	  		      hide-details
	  		      dense
	  		      type="date"
	  		      v-model="fecha_inicio"
	  		    ></v-text-field>
	  		    <v-spacer></v-spacer>

	  		    <v-text-field
	  		      label="Fecha final"
	  		      filled
	  		      hide-details
	  		      dense
	  		      type="date"
	  		      v-model="fecha_fin"
	  		    ></v-text-field>
	  		    <v-spacer></v-spacer>
	  		    <v-select
	  		      :items="[{id: 1, texto:'INBI'},{id: 2, texto:'FAST'}]"
	  		      v-model="escuela"
	  		      label="Escuela"
	  		      hide-details
	  		      filled
	  		      dense
	  		      item-value="id"
	  		      item-text="texto"
	  		      clearable
	  		    ></v-select>

	  		    <v-spacer></v-spacer>
	  		    
	  		    <v-autocomplete
              :items="vendedoras"
              v-model="vendedora"
              label="Selecciona vendedora"
              filled
              hide-details
              item-value="idusuario"
              item-text="nombre_completo"
              clearable
            ></v-autocomplete>

	  		    <v-spacer></v-spacer>

	  		    <v-btn color="primary" @click="initialize()">Buscar</v-btn>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		</v-card>
  		</v-col>
  	</v-row>

  	<v-row>
  		<v-col cols="12">
  			<v-card class="shadowCard">
	  			<v-data-table
				    :headers="headers"
				    :items="vendedoras"
				    :items-per-page="vendedoras.length"
				    class="elevation-1"
				    dense
				  >
				  	
				  	<template v-slot:item.llamadasExternas="{ item }">
				  		<span>{{ item.llamadasExternas.length }}</span>
				    </template>

				    <template v-slot:item.exLlamadasOutAnswer="{ item }">
				    	<span>{{ item.exLlamadasOutAnswer.length }}</span>
				    </template>

				    <template v-slot:item.exLlamadasOutAnswerTime5min="{ item }">
				    	<span>{{ item.exLlamadasOutAnswerTime5min.length }}</span>
				    </template>

				    <template v-slot:item.efectividad="{ item }">
				    	<span>{{ item.efectividad }}</span>
				    </template>

				  </v-data-table>
  			</v-card>
  		</v-col>
  	</v-row>

    <v-row>
    	<!-- Cantidad de LEDS -->
      <v-col cols="12" v-for="(vendedora , i) in filterVendedoras" :key="i">
        <v-card class="shadowCard">
			    <v-card-title class="align-start">
			      <span class="font-weight-semibold pink--text">{{ vendedora.nombre_completo }} 
			      	<br/><span class="black--text">Llamadas: {{ vendedora.totalLlamadas.length }}</span>
			      	<br/><span class="black--text">Efectividad: {{ vendedora.efectividad }}</span> </span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			      <v-row>

			      	<!-- LLAMADAS EXTERNAS -->
			        <v-col
			          cols="6"
			          md="6"
			        >
			        	<!-- Internas -->
			        	<div class="d-flex align-center">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="blue"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-phone-forward
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Llamadas externas </span>
							    </v-tooltip>
				          <div class="ms-3">
				            <p class="text-xl mb-0">
				              Externas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.llamadasExternas.length }}
				            </h3>
				          </div>
			        	</div>

			        	<!-- INTERNAS ENTRANTES -->
			        	<div class="d-flex align-center mt-4">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="green"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-phone-incoming
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Entrantes</span>
							    </v-tooltip>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasInAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              NO respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasInNOAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Ocupados
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasInBusy.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Fallidos
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasInFailed.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              TOTAL
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasEntrantes.length }}
				            </h3>
				          </div>
			        	</div>

			        	<!-- INTERNAS SALIENTES -->
			        	<div class="d-flex align-center mt-4">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="red"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-phone-outgoing
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Salientes </span>
							    </v-tooltip>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              NO respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutNOAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Ocupados
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutBusy.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Fallidos
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutFailed.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              TOTAL
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasSalientes.length }}
				            </h3>
				          </div>
				        </div>
			        </v-col>
			        
			        <!-- Internas -->
			        <v-col
			          cols="6"
			          md="6"
			        >
			        	<div class="d-flex align-center">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="blue"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-phone-return
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Llamadas internas </span>
							    </v-tooltip>
				          <div class="ms-3">
				            <p class="text-xl mb-0">
				              Internas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.llamadasInternas.length }}
				            </h3>
				          </div>
			        	</div>

			        	<!-- INTERNAS ENTRANTES -->
			        	<div class="d-flex align-center mt-4">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="green"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-phone-incoming
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Entrantes</span>
							    </v-tooltip>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasInAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              NO respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasInNOAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Ocupados
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasInBusy.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Fallidos
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasInFailed.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              TOTAL
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasEntrantes.length }}
				            </h3>
				          </div>
			        	</div>

			        	<!-- INTERNAS SALIENTES -->
			        	<div class="d-flex align-center mt-4">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="red"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-phone-outgoing
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Salientes </span>
							    </v-tooltip>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasOutAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              NO respondidas
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasOutNOAnswer.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Ocupados
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasOutBusy.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              Fallidos
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasOutFailed.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xs mb-0">
				              TOTAL
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.inLlamadasSalientes.length }}
				            </h3>
				          </div>
				        </div>
			        </v-col>

			        <v-col
			          cols="6"
			          md="6"
			        >
			        	<div class="d-flex align-center">
				        	<v-tooltip bottom >
							      <template v-slot:activator="{ on, attrs }">
						          <v-avatar
						          	@click="abrirModalDesgloce( vendedora )"
						            size="44"
						            rounded
						            class="elevation-1"
						            v-bind="attrs"
									      v-on="on"
									      color="blue"
						          >
						            <v-icon
						              dark
						              color="white"
						              size="30"
						            >
						              mdi-clock
						            </v-icon>
						          </v-avatar>
							      </template>
							      <span>Duración de llamadas salientes respondidas</span>
							    </v-tooltip>

				          <div class="ms-3">
				            <p class="text-xl mb-0">
				              Mayor a 5 min 
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutAnswerTime5min.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xl mb-0">
				              Menor a 5 Min
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutAnswerTimeNo5min.length }}
				            </h3>
				          </div>

				          <div class="ms-3">
				            <p class="text-xl mb-0">
				              TOTAL 
				            </p>
				            <h3 class="text-xl font-weight-semibold">
				              {{ vendedora.exLlamadasOutAnswer.length }}
				            </h3>
				          </div>
			        	</div>
			        	
			        </v-col>
			      </v-row>
			    </v-card-text>
			  </v-card>
      </v-col>

    </v-row>

    <!-- Dialog de desglose de contactos -->
    <v-dialog
			v-model="dialogDesglose"
			max-width="600px"
		>
			<v-card>
				<v-card-title primary-title>
					<v-avatar
            size="44"
            :color="fuente.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ fuente.icono }}
            </v-icon>
          </v-avatar>
				  {{ fuente.fuente }}
				  <v-spacer></v-spacer>
				  <v-btn icon small class="me-n3 mt-n2" @click="dialogDesglose = false">
		        <v-icon>
		          mdi-close
		        </v-icon>
		      </v-btn>
				</v-card-title>
				<v-card-text class="pt-4">
					<v-data-table
			      :headers="headersPlantel"
			      :items="desglose"
			      class="table-rounded"
			      hide-default-footer
			      disable-sort
			    >
			    </v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

	export default {
		components:{
	    Alerta,
	    carga,
	  },

    mixins: [ validarErrores ],

	  data: () => ({
	  	// Alertas
	  	parametros:{
	      dialogError: false,
	      mensaje: '',
	      color: ''
	    },

	    respuestaAlerta:false,
	    loader: true,
	    cargar: false,

			fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_fin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			
			vendedoras: [],

			dialogDesglose: false,
			desglose:[],
			fuente:'',

			headers: [
	      { text: 'VENDEDORA'       , value: 'nombre_completo' },
	      { text: 'Externas'        , value: 'llamadasExternas' },
	      { text: 'Respondidas'     , value: 'exLlamadasOutAnswer' },
	      { text: 'Mayor a 5 min'   , value: 'exLlamadasOutAnswerTime5min' },
	      { text: 'Efectividad'     , value: 'efectividad' },
	    ],

	    headersPlantel: [
	      { text: 'PLANTEL'          , value: 'plantel' },
	      { text: 'CONTACTOS'        , value: 'contactos' },
	      { text: 'INSCRITOS'        , value: 'cantidadInscritos' },
	      { text: '% CONVENCIMIENTO' , value: 'convencimiento' },
	    ],

	    vendedora: null,
	    datos:[],
	    escuela: null,
		}),

	  async created(){
	  	await this.initialize()
	  },

	  methods:{
	  	initialize(){
	  		this.cargar = true
	  		const payload = {
	  			fecha_inicio: this.fecha_inicio,
	  			fecha_fin:    this.fecha_fin,
	  		}
	  		this.vendedoras = []
				this.$http.post('conmutador.reporte.llamadas', payload).then((response) => {
					this.vendedoras  = response.data
					console.log( this.vendedoras )
					this.cargar = false
	        return
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	  	},

	  	abrirModalDesgloce( vendedora ){
	  		this.fuente         = data
	  		this.desglose       = data.detalles
	  		this.dialogDesglose = true
	  	}
	  },

	  computed:{
	    tamanioPantalla () {
	      switch (this.$vuetify.breakpoint.name) {
	        case 'xs':
	        return 'auto';
	          // return this.$vuetify.breakpoint.height -110
	        break;
	        case 'sm': 
	          return this.$vuetify.breakpoint.height - 65
	        break;
	        case 'md':
	          return this.$vuetify.breakpoint.height - 65
	        break;
	        case 'lg':
	          return this.$vuetify.breakpoint.height - 65
	        break;
	        case 'xl':
	          return this.$vuetify.breakpoint.height - 65
	        break;
	      }
	    },

	    filterVendedoras( ){
	    	let data = this.vendedoras
	    	if( this.vendedora ){
	    		data = data.filter( el => { return el.idusuario == this.vendedora })
	    	}

	    	if( this.escuela ){
	    		data = data.filter( el => { return el.escuela == this.escuela })
	    	}
	    		
	    	return data
	    	
	    }
	  }
	}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>